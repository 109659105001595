jQuery(document).ready(function($){

  scrolled();
  backToTop();
  jsScroll();
  scrollSpy();
  closeMenu();

  //On execute la fonction qui doit s'executer au scroll
  $(document).scroll(function() {
    scrolled();
    backToTop();
  });

  //On appelle la/les fonction(s) qui doit s'éxécuter au resize
  $(window).resize(function(e) {

  });

});

//Fonction qui détermine si on as scrollé ou non, on effectue des actions selon le cas
function scrolled(){
  //On récupère la valeur du scroll
  var scrollVal = $(document).scrollTop();
  if (scrollVal > $('#header').outerHeight()){
    $('body').addClass("scrolled");
    $('#logo').attr('src', 'img/logo-mca-gestion-white.svg');
  }else{
    $('body').removeClass("scrolled");
    $('#logo').attr('src', 'img/logo-mca-gestion.svg');
  }
}

// Bouton retour haut de page
function backToTop(){
  var pxShow = 200,
      fadeInTime = 400,
      fadeOutTime = 400,
      goTopButton = $(".back-to-top")

  $(window).on('scroll', function () {
      if ($(window).scrollTop() >= pxShow) {
          goTopButton.fadeIn(fadeInTime);
      } else {
          goTopButton.fadeOut(fadeOutTime);
      }
  });
}

//Smoothscroll
function jsScroll(){
  var mainNav_height = $('#navbar-menu').outerHeight() - 22;
  $('.js-scroll').on("click", function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        var scrollto = target.offset().top - mainNav_height;
        $('html, body').animate({
          scrollTop: scrollto
        }, 1000, "swing");
        return false;
      }
    }
  });
}

//ScrollSpy
function scrollSpy(){
  $('body').scrollspy({ 
    target: '#navbar-menu',
    offset: $('#navbar-menu').outerHeight()
  });
}

//Fermeture du menu Responsive au clic
function closeMenu(){
  $('.js-scroll').on("click", function() {
    $('.navbar-collapse').collapse('hide');
  });
}

//Cookies
// function getCookie(cname) {
//   const name = cname + "=";
//   const decodedCookie = decodeURIComponent(document.cookie);
//   const ca = decodedCookie.split(';');

//   for(let i = 0; i < ca.length; i++) {
//       let c = ca[i];
//       while (c.charAt(0) === ' ') {
//           c = c.substring(1);
//       }
//       if (c.indexOf(name) === 0) {
//           return c.substring(name.length, c.length);
//       }
//   }

//   return "";
// }

// function setCookie(cname, cvalue, exdays) {
//   const d = new Date();

//   d.setTime(d.getTime() + (exdays*24*60*60*1000));

//   const expires = "expires="+ d.toUTCString();

//   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
// }


// const cookieBanner = document.querySelector('#cookie-consent');
// const hasCookieConsent = getCookie('cookies-consent');

// if (!hasCookieConsent) {
//     cookieBanner.classList.remove('hidden');
// }

// const consentCta = cookieBanner.querySelector('#consent');

// consentCta.addEventListener('click', () => {
//     cookieBanner.classList.add('hidden');
//     setCookie('cookies-consent', 1, 365);
// });
